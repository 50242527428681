<template>
  <div class="modification-coupons">
    <div class="distribution-communautaire">DISTRIBUTION COMMUNAUTAIRE</div>
    <div class="ligne-pale" />
    <div class="titre" v-if="!creation"><h1>Modifier un coupon</h1></div>
    <div class="titre" v-else><h1>Création d'un coupon</h1></div>
    <div class="contenu">
      <div class="choix-boite fond-blanc">
        <h2>Boites demandées</h2>
        <div class="ligne-pale"></div>
        <div class="boites">
          <div
            v-for="produitDC in produitsDC"
            v-bind:key="produitDC.id"
            class="choix-container-item"
          >
            <input
              type="checkbox"
              class="choix-container-item-checkbox"
              :value="produitDC.id"
              v-model="selectedCoupon"
              @change="updateSelectedProducts(produitDC)"
            />
            <span>{{ produitDC.nom }}</span>
            <img :src="produitDC.path_icone" alt="logo" />
          </div>
        </div>
        <div v-if="errorMessage">
          <p class="errorMessage">{{ errorMessage }}</p>
        </div>
      </div>
      <div class="formulaire fond-blanc">
        <h2>Information pour l'envoi</h2>
        <div class="ligne-pale"></div>
        <form action="Modification">
          <div v-if="coupon.created_by_id || creation" class="form-no-membre">
            <label for="no-membre">Numéro de membre</label>
            <input
              type="text"
              id="no-membre"
              placeholder="12-12345"
              v-model="coupon.memberId"
              v-on:blur="onBlur()"
            />
            <p
              class="errorMessage"
              v-for="error of v$.coupon.memberId.$errors"
              :key="error.$uid"
            >
              <span v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </span>
            </p>
          </div>
          <div v-if="coupon.created_by_id || creation" class="form-nom">
            <label for="nom">Nom</label>
            <input
              type="text"
              id="nom"
              placeholder="Nom"
              v-model="coupon.lastName"
              v-on:blur="onBlur()"
            />
            <p
              class="errorMessage"
              v-for="error of v$.coupon.lastName.$errors"
              :key="error.$uid"
            >
              <span v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </span>
            </p>
          </div>

          <div v-if="coupon.created_by_id || creation" class="form-prenom">
            <label for="prenom">Prénom</label>
            <input
              type="text"
              id="prenom"
              placeholder="Prénom"
              v-model="coupon.firstName"
              disabled
            />
            <p
              class="errorMessage"
              v-for="error of v$.coupon.firstName.$errors"
              :key="error.$uid"
            >
              <span v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </span>
            </p>
          </div>
          <div v-if="coupon.created_by_id || creation" class="form-adresse">
            <label for="adresse">Adresse</label>
            <input
              type="text"
              id="addresse"
              placeholder="Adresse"
              v-model="coupon.adress"
              disabled
            />
            <p
              class="errorMessage"
              v-for="error of v$.coupon.adress.$errors"
              :key="error.$uid"
            >
              <span v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </span>
            </p>
          </div>
          <div v-if="coupon.created_by_id || creation" class="form-ville">
            <label for="ville">Ville</label>
            <input
              type="text"
              id="ville"
              placeholder="Ville"
              v-model="coupon.city"
              disabled
            />
            <p
              class="errorMessage"
              v-for="error of v$.coupon.city.$errors"
              :key="error.$uid"
            >
              <span v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </span>
            </p>
          </div>
          <div v-if="coupon.created_by_id || creation" class="form-province">
            <label for="Province">Province</label>
            <select
              name="Province"
              id="Province"
              v-model="coupon.province"
              disabled
            >
              <option
                v-for="provinces in provinces"
                :value="provinces.abbreviation"
                :key="provinces.abbreviation"
              >
                {{ provinces.name }}
              </option>
            </select>
            <p
              class="errorMessage"
              v-for="error of v$.coupon.province.$errors"
              :key="error.$uid"
            >
              <span v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </span>
            </p>
          </div>
          <div v-if="coupon.created_by_id || creation" class="form-code-postal">
            <label for="code-postal">Code postal</label>
            <input
              type="text"
              id="code-postal"
              placeholder="A1A 1A1"
              v-model="coupon.postalCode"
              disabled
            />
            <p
              class="errorMessage"
              v-for="error of v$.coupon.postalCode.$errors"
              :key="error.$uid"
            >
              <span v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </span>
            </p>
          </div>
          <div v-if="coupon.created_by_id || creation" class="form-email">
            <label for="email">Courriel</label>
            <input
              type="email"
              id="email"
              placeholder="Courriel"
              v-model="coupon.email"
              disabled
            />
            <p
              class="errorMessage"
              v-for="error of v$.coupon.email.$errors"
              :key="error.$uid"
            >
              <span v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </span>
              <span v-if="error.$validator == 'email'">
                courriel incorrect
              </span>
            </p>
          </div>
          <div v-if="coupon.created_by_id || creation" class="form-telephone">
            <label for="telephone">Téléphone</label>
            <input
              type="text"
              id="telephone"
              placeholder="000-000-0000"
              v-model="coupon.telephone"
              v-maska="'(###) ###-####'"
              disabled
            />
            <p
              class="errorMessage"
              v-for="error of v$.coupon.telephone.$errors"
              :key="error.$uid"
            >
              <span v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </span>
              <span v-if="error.$validator == 'minLength'">
                Téléphone incorrect
              </span>
            </p>
          </div>
          <div class="form-site">
            <label for="site">
              Site de distribution où vous irez chercher votre commande
            </label>
            <select v-model="coupon.site_distribution_id">
              <option
                v-for="adress in adresses"
                :key="adress.id"
                :value="adress.id"
              >
                {{ uppercase(adress.ville) }} - {{ adress.lieux }}
              </option>
            </select>
            <p
              class="errorMessage"
              v-for="error of v$.coupon.site_distribution_id.$errors"
              :key="error.$uid"
            >
              <span v-if="error.$validator == 'required'">
                {{ requiredMessage }}
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
    <div class="bouton">
      <button class="button button-primary" @click="submitform()">
        <font-awesome-icon
          :icon="['fas', 'box-check']"
          class="box-check-logo"
        />
        Sauvegarder les modifications
      </button>
      <ConfirmDialog></ConfirmDialog>
      <button
        class="button button-primary delete"
        @click="deleteCoupon()"
        v-if="!creation"
      >
        <font-awesome-icon
          :icon="['fas', 'trash-can']"
          class="box-check-logo"
        />
        Supprimer le coupon
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import provincesJson from '../data/provinces.json';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';
import ConfirmDialog from 'primevue/confirmdialog';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
import { ProduitDCDTO } from '@/types/store/DTO/ProduitDCDTO';

export default defineComponent({
  name: 'CreationModificationCoupon',
  components: {
    ConfirmDialog,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      provinces: provincesJson,
      adresses: {} as Array<siteDistributionDTO>,
      coupon: {} as CommandeDTO,
      creation: false,
      error: false,
      requiredMessage: 'champ requis',
      errorMessage: '',
      produitsDC: {} as Array<ProduitDCDTO>,
      cart: [] as Array<ProduitDCDTO>,
      selectedCoupon: [],
    };
  },
  validations: {
    coupon: {
      firstName: { required },
      lastName: { required },
      adress: { required },
      city: { required },
      postalCode: { required },
      telephone: { minLength: minLength(12) },
      email: { email },
      site_distribution_id: { required },
      province: { required },
      memberId: { required },
    },
  },
  created() {
    this.siteDistrib();
    this.produits();
    if (this.$route.params.id) {
      this.creation = false;
      this.getCommandInfo(this.$route.params.id as string);
    } else {
      this.creation = true;
    }
  },
  methods: {
    onBlur() {
      if (
        this.coupon.lastName != undefined &&
        this.coupon.lastName != '' &&
        this.coupon.memberId != '' &&
        this.coupon.memberId != undefined
      ) {
        this.$loading.startLoading();

        let data = {
          lastName: this.coupon.lastName,
          memberId: this.coupon.memberId,
        };

        this.$store.dispatch('Commande/feedCoupon', data).then((data) => {
          if (data != '') {
            let couponData = {
              adress: data.address,
              memberId: data.bandNumber,
              birthDate: data.birthDate,
              city: data.city,
              country: data.country,
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              postalCode: data.postalCode,
              telephone: data.telephone,
              province: data.province,
              aggregateRootId: data.memberId,
            };
            this.coupon = couponData;
          } else {
            let couponData = {
              adress: null,
              memberId: this.coupon.memberId,
              birthDate: null,
              city: null,
              country: null,
              email: null,
              firstName: null,
              lastName: this.coupon.lastName,
              postalCode: null,
              telephone: null,
              province: null,
              aggregateRootId: null,
            };
            this.coupon = couponData;
          }
          this.$loading.stopLoading();
        });
      } else {
        let couponData = {
          adress: null,
          memberId: this.coupon.memberId,
          birthDate: null,
          city: null,
          country: null,
          email: null,
          firstName: null,
          lastName: this.coupon.lastName,
          postalCode: null,
          telephone: null,
          province: null,
          aggregateRootId: null,
        };
        this.coupon = couponData;
      }
    },
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.adresses = data;
        });
    },
    produits() {
      this.$store
        .dispatch('ProduitDC/getAllProduitsDC')
        .then((data: Array<ProduitDCDTO>) => {
          this.produitsDC = data;
        });
    },
    updateSelectedProducts(produitDC) {
      // console.log(produitDC);
      let obj = this.cart.find((prod) => prod.id === produitDC.id);
      if (obj !== undefined) {
        let index = this.cart.indexOf(obj);
        this.cart.splice(index, 1);
      } else {
        this.cart.push(produitDC);
      }
      // console.log(this.cart);
    },
    uppercase(text: string) {
      return text.toUpperCase();
    },
    getCommandInfo(id: string) {
      this.$store.dispatch('Commande/getCommandeById', id).then((data) => {
        if (data.ssamtaMemberId == null) {
          this.$router.push('/liste-coupons-admin');
        }
        this.coupon = data;
        this.coupon.produits.forEach((produit) => {
          this.updateSelectedProducts(produit);
          this.selectedCoupon.push(produit.id);
        });
      });
    },
    submitform() {
      if (this.creation) {
        this.v$.$touch();
        if (this.v$.$invalid) {
          return;
        }
      }
      this.$confirm.require({
        message:
          "Voulez-vous renvoyer un nouveau coupon à l'utilisateur? (La modification sera sauvegardé en tout temps)",
        header: 'Renvoyer un coupon?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: () => {
          this.coupon.notify = true;
          this.saveCoupon();
        },
        reject: () => {
          this.coupon.notify = false;
          this.saveCoupon();
        },
      });
    },
    saveCoupon() {
      let post_data = {};
      let uri = 'modifyCommande';
      if (this.creation) {
        uri = 'createCommande';
        post_data = {
          memberId: this.coupon.aggregateRootId,
          site_distribution_id: this.coupon.site_distribution_id,
          products: this.cart,
          notify: this.coupon.notify,
        };
      } else {
        post_data = {
          id: this.coupon.id,
          memberId: this.coupon.ssamtaMemberId,
          site_distribution_id: this.coupon.site_distribution_id,
          products: this.cart,
          notify: this.coupon.notify,
        };
      }

      this.$store
        .dispatch('Commande/' + uri, post_data)
        .then(() => {
          this.$loading.stopLoading();
          if (this.$route.params.previousPage == 'RapportDc') {
            this.$router.push('/community-distribution-report');
          } else {
            this.$router.push('/liste-coupons-admin');
          }
        })
        .catch((error) => {
          this.errorMessage = error;
        });
    },
    deleteCoupon() {
      this.$store
        .dispatch('Commande/deleteCommande', this.$route.params.id as string)
        .then(() => {
          this.$loading.stopLoading();
          this.$router.push('/liste-coupons-admin');
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.fond-blanc {
  background-color: $white;
}
.distribution-communautaire {
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  color: $grisFonce;
  margin: 2% 0 1% 0;
  @media screen and (max-width: $md) {
    margin: 5% 0 1% 0;
  }
}
.ligne-pale {
  border: 1px solid $grisFonce;
  width: 100%;
}
.choix-boite,
.formulaire {
  width: 65%;
  border-radius: 4px;
  box-shadow: 0px 0px 6px #87a8ea21;
  margin: 1%;
  padding: 1%;
  @media screen and (max-width: $md) {
    width: 80%;
    padding: 2%;
  }
  @media screen and (max-width: 425px) {
    padding: 2%;
  }
}
.titre {
  text-align: center;
  margin: 2%;
  @media screen and (max-width: $md) {
    margin: 5%;
  }
}
.contenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.bouton {
  display: flex;
  justify-content: space-between;
  margin-left: 17.5%;
  margin-right: 17.5%;
  margin-bottom: 5%;
  @media screen and (max-width: $md) {
    margin-left: 10%;
  }
  @media screen and (max-width: 425px) {
    display: flex;
    justify-content: center;
    margin-left: 10%;
  }
}
h2 {
  font-size: 17px;
  font-family: $police;
  font-weight: 600;
  margin-bottom: 0.5%;
}
.boites {
  display: flex;
  margin: 3% 0% 2% 0%;
  font-size: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media screen and (max-width: 600px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 20%;
  }
  @media screen and (max-width: 375px) {
    margin-left: 12%;
  }
  @media screen and (max-width: 355px) {
    margin-left: 6%;
  }
  .choix-container-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px) {
      flex-basis: 100%;
    }

    img {
      width: 50px;
      height: 50px;
    }

    &-checkbox {
      width: 1em;
      height: 1em;
      margin-right: 10%;
      accent-color: $bleuFonce;
    }
  }
}
select {
  max-width: 100%;
}
form {
  margin: 3% 0 1.5% 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-prenom {
  flex-basis: 38%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    flex-basis: 30%;
    margin-bottom: 5%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 100%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-prenom input {
  width: 100%;
}
.form-nom {
  flex-basis: 35%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    margin-bottom: 5%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 50%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
    flex-basis: 100%;
  }
}
.form-nom input {
  width: 100%;
}
.delete {
  background-color: red;
}
.form-no-membre {
  flex-basis: 20%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    flex-basis: 30%;
    margin-bottom: 5%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 45%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
    margin-left: 0;
    flex-basis: 55%;
  }
}
.form-no-membre input {
  width: 100%;
}
.form-adresse {
  flex-basis: 100%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    margin-bottom: 5%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-adresse input {
  width: 100%;
}
.form-ville {
  flex-basis: 38%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    flex-basis: 30%;
    margin-bottom: 5%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 50%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-ville input {
  width: 100%;
}
.form-province {
  flex-basis: 35%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    margin-bottom: 5%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 45%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-province select {
  width: 100%;
}
.form-code-postal {
  flex-basis: 20%;
  margin-left: 2%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    flex-basis: 30%;
    margin-bottom: 5%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 50%;
    margin-left: 0;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-code-postal input {
  width: 100%;
}
.form-email {
  flex-basis: 38%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    flex-basis: 30%;
    margin-bottom: 5%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 45%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-email input {
  width: 100%;
}
.form-telephone {
  flex-basis: 35%;
  margin-bottom: 3%;
  margin-right: 24.5%;
  @media screen and (max-width: 1070px) {
    margin-right: 33.5%;
    margin-bottom: 5%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 35%;
    margin-right: 0;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
    flex-basis: 55%;
  }
}
.form-telephone input {
  width: 100%;
}
.form-site {
  flex-basis: 100%;
  @media screen and (max-width: 1070px) {
    margin-bottom: 5%;
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 10%;
  }
  @media screen and (max-width: 350px) {
    margin-bottom: 15%;
  }
}
.form-site select {
  width: 100%;
}
input[type='text'],
input[type='email'],
select {
  border: 1px solid $colorGrisBorder;
  border-radius: 3px;
  height: 45px;
}
form label {
  color: $bleuFonce;
  font-size: 15px;
  margin-bottom: 0.2%;
}

.errorMessage {
  font-size: 14px;
  color: red;
}
.bouton {
  margin-left: 17.5%;
  margin-bottom: 5%;
  @media screen and (max-width: $md) {
    margin-left: 10%;
  }
  @media screen and (max-width: 425px) {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
}
button.button {
  border: none;
  height: 3em;
}
input[type='text'],
input[type='email'],
select {
  padding-left: 10px;
}
</style>
